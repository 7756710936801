<template>
  <div>
    <div class="mb-2 d-flex justify-content-between align-items-end">
      <div>
        <h2 class="mb-0">Internal Storage</h2>
        <div class="text-secondary">
          <div>
            Internal Storage is a Database of sustained data that can be accessed during Flux Executions
          </div>
          <div>
            <b-icon icon="arrow-right-short"/>
            Use <b>Records</b> with the Agents <b>Get Record</b>, <b>Set Record</b> and <b>Delete Record</b>
          </div>
        </div>
      </div>
      
      <div class="mx-1">
        <div class="font-weight-bold mb-25">
          <b-icon icon="exclamation-triangle" variant="warning" scale="1.3" class="mr-50"/>
          This should not be used to store accumulative data
        </div>
        <div align="left" class="small text-secondary ml-1 border-left-secondary pl-50 d-block font-weight-bolder">
          <span class="text-dark">Internal Storage</span>  is limited to:
          
          <div>
            <span>
                <span class="text-dark">1.000 Records</span>
                 and 
                 <span class="text-dark">65KB of data each</span> 
            </span>
          </div>
          
        </div>
      </div>
    </div>
    

    <div align="right" class="d-flex py-1 sticky-record-header">
      
      <div class="w-100 pr-1">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            placeholder="Search by Key or Value"
            type="search"
            v-model="tableConfig.tableSearchFilter"
          />
        </b-input-group>
      </div>

      <b-button variant="success" class="py-50 no-word-wrap" @click="createRecord()" :disabled="!getStoredRecords || loadingAdd || !$can('create')" >
        <span class="text-black font-weight-bolder" v-if="!loadingAdd">          
          <b-icon icon="plus-circle" class="mr-50" scale="1.2"/>
          Add Record
        </span>
        <b-spinner variant="black" class="mx-4" v-else small/>
      </b-button>
    </div>

    <div v-if="getStoredRecords" class="position-relative">
      <!-- <div class="text-secondary float-right-table-header" v-if="getStoredRecords && getStoredRecords.length > 0">
        <div class="py-50 mr-1 font-weight-bolder">
          {{ getStoredRecords.length }} total Records
        </div>

      </div> -->

      <div class="rounded overflow-hidden">
        <b-table
          :items="getStoredRecords"
          :fields="tableConfig.fields"
          :sort-by.sync="tableConfig.sortBy"
          :filter="tableConfig.tableSearchFilter" 
          :sort-desc.sync="tableConfig.sortDesc"
          :current-page="tableConfig.currentPage"
          :per-page="tableConfig.rowsPerPage"
          show-empty
          empty-filtered-text="No Records matched"
          sort-icon-left
          class="custom-td-records-table mb-0"
          @sort-changed="resetRowExpanded()"
        >
          <!-- @row-clicked="expandRow" -->
          <template  #cell(key)="data">
            <div class="d-flex align-items-center" v-if="editingName.id != data.item.id || editingName.loading">
              <span class="record-key-elipsing">
                {{ data.item.key }}
              </span>
              <b-spinner v-if="editingName.loading && editingName.id == data.item.id" class="ml-1" small variant="info" />
              <b-button class="ml-50 p-0" variant="none" @click="editRecordName(data.item)" v-else-if="$can('create')">
                <b-icon icon="pencil-square" variant="dark" scale="0.9" /> 
              </b-button> 
            </div>
            <template v-else>
              <div class="d-flex align-items-center">
                <b-form-input size="sm" autofocus v-model.trim="editingName.value" @keydown.enter="saveEditingName()" class="record-key-width w-100 m-0" @focus="$event.target.select()"  @blur="cancelEditingName()"/>
                <b-button class="ml-50 p-25 round" variant="outline-success" @click="saveEditingName()" v-if="!editingName.loading">
                  <b-icon icon="check" scale="1.3" variant="success" class="cursor-pointer"/>
                </b-button>
              </div>
            </template>
          </template>

          <template #cell(updated_at)="data">
            <div>
              {{ treatTime(data.item.updated_at) }}
            </div>
          </template>

          <template #cell(value)="data">

            <b-collapse :visible="rowExpanded.id != data.item.id">
              <div class="mt-50">
                <span class="record-value-elipsing" @click="expandRow(data.item)">
                  {{ data.item.value }}
                </span>
              </div>
            </b-collapse>

            <b-collapse appear :visible="rowExpanded.id == data.item.id">
              <div class="py-50 position-relative" @keydown.ctrl.enter="saveRecordValue()" @keydown.ctrl.s="((e)=>{e.preventDefault(); saveRecordValue()})">   
                <json-editor
                  class="border-secondary"
                  v-model="rowExpanded.value"
                  :modes="['code', 'tree', 'preview']" 
                  :readOnly="!$can('create')"
                  height="300px"
                  @input="rowExpanded.canSave = true"
                >
                  <template #title>
                    <b-button variant="none" class="py-25 mt-25" @click="rowExpanded.id = null">
                      <b-icon icon="dash" variant="black" scale="2"/>
                    </b-button>
                  </template>
                </json-editor>
                
                <b-button variant="success" class="py-50 save-value-btn" @click="saveRecordValue()" :disabled="rowExpanded.saving" v-if="rowExpanded.canSave">
                  <span class="text-black" v-if="!rowExpanded.saving">
                    Save <b-icon icon="check"/> 
                  </span>
                  <b-spinner v-else class="mx-2" small variant="black"/>
                </b-button>
              </div>
            </b-collapse>
          </template>


          <template #head(actions)>
            <div/>            
          </template>

          <template #cell(actions)="data">
            <div class="record-table-padding">
              <b-button variant="outline-danger" class="py-25 px-50" @click="openModalDelete(data.item)" v-if="$can('create')">
                <b-icon icon="trash"/>
              </b-button>
            </div>
          </template>


          <template #empty>
            <div align="center" class="my-2">
              <h3 class="text-muted">
                No Records stored
              </h3>
            </div>
          </template>


        </b-table>
      </div>
          
      <b-pagination 
        v-model="tableConfig.currentPage"  
        :total-rows="getStoredRecords.length" 
        :per-page="tableConfig.rowsPerPage"
        prev-class=""
        next-class=""
        first-number
        last-number
        class="mt-1"
        align="center"
        :limit="3"
      />          
    </div>
      <b-skeleton-table
      v-else
      :columns="3"
      :rows="4"
      />
    <b-modal
      ref="modal-delete-record"
      centered
      ok-variant="danger"
      auto-focus-button="ok"
      :ok-disabled="modalInfo.loading"
      @hide="((e) =>{ if (modalInfo.loading) e.preventDefault()})"
      @ok="deleteRecord(modalInfo.row)" 
    > 
      <template #modal-title>
        Confirm delete
      </template>

      <p v-if="modalInfo.row">
      Delete record <b>{{ modalInfo.row.key }}</b>?
      </p>

      <template #modal-ok>
        <span v-if="!modalInfo.loading">Confirm</span>
        <b-spinner small v-else class="mx-1"/>
      </template>

    </b-modal>
  </div>
</template>

<script>



import {
  BPopover,
  BIcon,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BContainer,
  BSidebar,
  BSpinner,
  BTabs,
  BTab,
  BCardText,
  BCardBody,
  BCard,
  BAvatar,
  BSkeleton,
  BFormCheckbox,
  BCollapse,
  BTable,
  BInputGroupPrepend,
  BPagination,
  BSkeletonTable,
} from 'bootstrap-vue'

import { mapGetters } from 'vuex'
import { errorToast, successToast } from '@/custom/class/FunctionClasses/CommonToasts'
import moment from 'moment'
import JsonEditor from '@/layouts/components/JsonEditor/JsonEditor.vue'

export default {
  components: {
    BPopover,
    BIcon,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BContainer,
    BSidebar,
    BSpinner,
    BTabs,
    BTab,
    BCardText,
    BCardBody,
    BCard,
    BAvatar,
    BSkeleton,
    BFormCheckbox,
    BCollapse,
    BTable,
    BInputGroupPrepend,
    BPagination,
    JsonEditor,
    BSkeletonTable,
  },
  data() {
    return {
      editingName: {
        id: null,
        value: null,
        loading: false,
      },
			tableConfig: {
				sortBy: 'created_at',
				sortDesc: true,
				tableSearchFilter: '',
				rowsPerPage: 20,
        currentPage: 1,
        fields: [
          { key: 'key',label: "Key", sortable: true, thStyle: "width: 10vw; max-width: 12vw;"},
          { key: 'updated_at', label: "Last Updated", sortable: true, thStyle: "width: 300px; max-width: 350px;"},
          { key: 'value', label: "Value", sortable: false, thStyle: "min-width: 500px"},
          { key: 'actions', label: "actions", sortable: false, thStyle: "width: 50px;", class:"border-left text-right"}
        ],
			},
      modalInfo:{
        row: null,
        loading: false,
      },
      rowExpanded: {
        id: null,
        value: null,
        saving: false,
        canSave: false,
      },

      loadingAdd: false,
      timeoutLoopFetch: undefined,
    }
  },
  computed: { 
		...mapGetters('internalStorage', ['getStoredRecords']),
    transmissionID() {
      return this.$route.params.transmissionID 
    },
    strokeDashoffset() {
      return this._circumference - percent / 100 * this._circumference;
    }
  },
  mounted () {
    this.init();
  },
  destroyed(){
    clearTimeout(this.timeoutLoopFetch)
  },
  methods: {
    init(){
      this.fetchStoredRecods()
    },
    fetchStoredRecods(){
    
      this.$store.dispatch("internalStorage/getRecords", {transmissionID: this.transmissionID})
        .then((resp)=>{})
        .catch((err)=>{
          console.error(err)
        })

        this.timeoutLoopFetch = setTimeout(() => {
          this.fetchStoredRecods()
        }, 1 * 60 * 1000 ); //reloads every minute
    },
    createRecord() {
      this.loadingAdd = true
      this.resetRowExpanded()
      this.$store.dispatch('internalStorage/createRecord', {transmissionID: this.transmissionID})
        .then((resp)=>{
          this.tableConfig.sortBy = "updated_at"
          this.tableConfig.sortDesc = true
          this.loadingAdd = false
          successToast({text: "New Record created"})
          this.$nextTick(()=>{
            this.editRecordName(resp)
          })
        })
        .catch((err)=>{
          console.error(err)
          this.loadingAdd = false
          errorToast({text: "Couldn't create Record"})
        })
    },
    editRecordName(record){
      this.editingName.id = record.id

      this.editingName.value = structuredClone(record.key)
    },
    cancelEditingName(){
      setTimeout(() => {
        this.editingName.id = null
        this.editingName.value = ""
        this.editingName.loading = false
      }, 200);
    },
    saveEditingName(){
      this.editingName.loading = true
      const editingRecord = this.getStoredRecords.find(el => el.id == this.editingName.id)

      if ((editingRecord.key == this.editingName.value) || this.editingName.value.length == 0){
        this.editingName.id = null
        this.editingName.value = ""
        this.editingName.loading = false
        return
      }
      
      const payload = {
        transmissionID: this.transmissionID,
        newValue: this.editingName.value,
        recordId: editingRecord.id
      }

      this.$store.dispatch("internalStorage/editRecordKey", payload)
        .then((resp)=>{          
          successToast({text: "Record Key updated"})
        })
        .catch((err)=>{
          console.error(err)
          if (err?.response?.data?.unique_code == 1062){
            errorToast({
              title: "Duplicate Keys error",
              text:"Key already exists"
            })
          } else {
            errorToast({text: "Couldn't update Key"})
          }
        })
        .finally(()=>{
          this.editingName.id = null
          this.editingName.value = ""
          this.editingName.loading = false
        })
    },
    saveRecordValue(){
      this.rowExpanded.saving = true
      const payload = {
        transmissionID: this.transmissionID,
        recordId: this.rowExpanded.id,
        newValue: JSON.stringify(this.rowExpanded.value)
      }
      this.$store.dispatch("internalStorage/editRecordValue", payload)
        .then((resp)=>{
          this.rowExpanded.canSave = false
          this.rowExpanded.saving = false
          successToast({text: "Record value updated"})
        })
    },
    openModalDelete(row){
      this.modalInfo.row = row;
      this.$refs['modal-delete-record'].show()
    },
    deleteRecord(row){
      this.modalInfo.loading = true
      this.$store.dispatch("internalStorage/deleteRecord", {transmissionID: this.transmissionID, recordId: row.id})
        .then((resp)=>{
          successToast({text: "Record deleted"})
          this.modalInfo.loading = false
          this.$nextTick(()=>{
            this.$refs['modal-delete-record'].hide()
          })
        })
        .catch((err)=>{
          console.error(err)
          this.modalInfo.loading = false
          errorToast("Couldn't delete Record")
        })
    },
    expandRow(row){
      this.rowExpanded.id = row.id
      this.rowExpanded.value = JSON.parse(row.value)
      this.rowExpanded.canSave = false
      this.rowExpanded.saving = false
    },
    resetRowExpanded(){
      this.rowExpanded.id = null;
      this.rowExpanded.value = null;
      this.rowExpanded.canSave = false
      this.rowExpanded.saving = false
    },
    treatTime(time){
      return new moment(time).calendar()
    }
  },
}
</script>

<style lang="scss">
.custom-td-records-table {
  td{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

$key-width: 13vw;


.float-right-table-header{
  position: absolute;
  right: 0;
  top: 0;
}
.record-key-elipsing{
  max-width: $key-width;
  display: inline-block;
  margin-bottom: 0;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}

.record-key-width{
  width: $key-width;
  min-width: $key-width;
}

.record-value-elipsing{
  display: inline-block;
  background-color: $monaco-bg-color;
  width: 100%;
  padding: 4px 5px;
  border-radius: 4px;
  outline: 1px solid $dark;
  font-family: monospace;
  max-height: calc(1em + 4px + 10px);
  //max-width: inherit;
  overflow: hidden;
  transition: all 0.1s;
  word-break: break-all;
  white-space: break-spaces;
  text-overflow: ellipsis;
  cursor: pointer;

  &:hover, &:focus{
    outline-color: $secondary;
  }
  &:active{
    outline-color: $light;
  }
}

.no-word-wrap{
  white-space: nowrap;
}

.record-table-padding{
  padding: 5px 0;
}
.save-value-btn{
  position: absolute;
  right: 5px;
  bottom: 13px;
}

.sticky-record-header{
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: $dark-blue;
}
</style>